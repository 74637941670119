import Draw, { modes as defaultModes } from '@mapbox/mapbox-gl-draw'
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode'
import {
  SnapDirectSelect,
  SnapLineMode,
  SnapModeDrawStyles,
  SnapPointMode,
  SnapPolygonMode,
} from 'mapbox-gl-draw-snap-mode'
import * as React from 'react'
import { useMap } from '../withMap'
import SnapRectangleMode from './modules/SnapRectangleMode'
import { SnappingOptions } from './modules/types'

const additionalCustomModes = {
  draw_rectangle: SnapRectangleMode,
}

const customOverrideModes = {
  draw_point: SnapPointMode,
  draw_polygon: SnapPolygonMode,
  draw_line_string: SnapLineMode,
  direct_select: SnapDirectSelect,
  static: StaticMode,
}

interface MapDrawContextTypes {
  draw?: Draw
  setSnapping: (options: SnappingOptions) => void
  setOnCreate: (onCreate: (ev: any) => void) => () => void
  setOnDelete: (onDelete: (ev: any) => void) => () => void
  setOnUpdate: (onUpdate: (ev: any) => void) => () => void
  setOnRender: (onRender: (ev: any) => void) => () => void
  setFeaturePropertyAndRepaint: (
    featureId: string,
    property: string,
    value: any
  ) => void
  attachedToMap: boolean
  isEnabled: boolean
  setIsEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

const MapDrawContext = React.createContext<MapDrawContextTypes>({
  isEnabled: false,
  setIsEnabled: () => {},
  setSnapping: () => {},
  setOnCreate: () => () => {},
  setOnDelete: () => () => {},
  setOnUpdate: () => () => {},
  setOnRender: () => () => {},
  setFeaturePropertyAndRepaint: () => {},
  attachedToMap: false,
})

export const useMapDrawContext = () => React.useContext(MapDrawContext)

/**
 * Provides a context for the map draw control.
 * This context is used to manage the draw control on the map.
 * It provides methods to set snapping options, styles, and event handlers.
 * It must be placed within a `MapContextProvider`.
 * Use the `useMapDrawContext` hook to access the context from a child component.
 * @param children The children to render under the context.
 * @param defaultSnappingOptions The default snapping options to use.
 * @param defaultStyle The default style to use.
 * @param foundationLayerId .
 * @returns The context provider.
 */
export const MapDrawContextProvider = ({
  children,
  defaultSnappingOptions,
  defaultStyle,
  setCustomLayerPositions,
}: {
  children: React.ReactNode
  defaultSnappingOptions?: SnappingOptions
  defaultStyle?: any
  setCustomLayerPositions?: (
    moveLayer: (id: string, beforeId?: string) => void
  ) => void
}) => {
  const { map } = useMap()
  const [isEnabled, setIsEnabled] = React.useState(false)

  const drawRef = React.useRef<Draw>()
  const [attachedToMap, setAttachedToMap] = React.useState(false)

  const setSnapping = (options: SnappingOptions) => {
    if (drawRef.current) {
      const { snap } = options
      drawRef.current.options.snap = snap
    }
  }

  const setOnCreate = (newOnCreate: (ev: any) => void) => {
    if (map && newOnCreate) {
      map.on('draw.create', newOnCreate)
    }

    return () => {
      if (map && newOnCreate) {
        map.off('draw.create', newOnCreate)
      }
    }
  }

  const setOnRender = (newOnRender: (ev: any) => void) => {
    if (map && newOnRender) {
      map.on('draw.render', newOnRender)
    }

    return () => {
      if (map && newOnRender) {
        map.off('draw.render', newOnRender)
      }
    }
  }

  const setOnDelete = (newOnDelete: (ev: any) => void) => {
    if (map && newOnDelete) {
      map.on('draw.delete', newOnDelete)
    }

    return () => {
      if (map && newOnDelete) {
        map.off('draw.delete', newOnDelete)
      }
    }
  }

  const setOnUpdate = (newOnUpdate: (ev: any) => void) => {
    if (map && newOnUpdate) {
      map.on('draw.update', newOnUpdate)
    }

    return () => {
      if (map && newOnUpdate) {
        map.off('draw.update', newOnUpdate)
      }
    }
  }

  const setFeaturePropertyAndRepaint = React.useCallback(
    (featureId: string, property: string, value: any) => {
      drawRef.current?.setFeatureProperty(featureId, property, value)
      const feature = drawRef.current?.get(featureId)
      if (!feature) {
        return
      }
      drawRef.current?.add(feature)
    },
    []
  )

  const createDraw = React.useCallback(() => {
    const { snap } = defaultSnappingOptions || {}

    const modes = {
      ...defaultModes,
      ...additionalCustomModes,
      ...customOverrideModes,
    }

    drawRef.current = new Draw({
      displayControlsDefault: false,
      defaultMode: 'simple_select',
      controls: {
        trash: true,
      },
      modes: modes,
      styles: defaultStyle ?? SnapModeDrawStyles,
      userProperties: true,
      snapOptions: defaultSnappingOptions || {
        snapPx: 5,
        snapVertexPriorityDistance: 1.25,
      },
      snap: snap,
      // note: snap mode guides appear to be broken with either our map lifecycle
      // or the current version of mapbox-gl-draw
      guides: false,
    })

    return () => {
      if (drawRef.current) {
        drawRef.current = undefined
      }
    }
  }, [
    defaultSnappingOptions,
    defaultModes,
    additionalCustomModes,
    customOverrideModes,
  ])

  React.useEffect(() => {
    if (!map) {
      return
    }
    const removeDraw = () => {
      if (drawRef.current && map.hasControl(drawRef.current)) {
        map.removeControl(drawRef.current)
        drawRef.current = undefined
        setAttachedToMap(false)
      }
    }
    if (!isEnabled) {
      if (attachedToMap) {
        removeDraw()
      }
      return
    }

    const loadDraw = () => {
      if (!drawRef.current) {
        createDraw()
        if (map && drawRef.current && !map.hasControl(drawRef.current)) {
          map.addControl(drawRef.current)
          setAttachedToMap(true)
        }
      }
    }

    const adjustCustomLayerPositions = () => {
      if (setCustomLayerPositions) {
        setCustomLayerPositions((id, beforeId) => {
          if (map.getLayer(id) && map.getLayer(beforeId ?? '')) {
            map.moveLayer(id, beforeId)
          }
        })
      }
    }

    map.once('load', loadDraw)
    loadDraw()
    map.once('remove', removeDraw)
    if (setCustomLayerPositions) {
      map.on('styledata', adjustCustomLayerPositions)
    }

    return () => {
      map.off('load', loadDraw)
      map.off('remove', removeDraw)
      if (setCustomLayerPositions) {
        map.off('styledata', adjustCustomLayerPositions)
      }
    }
  }, [map, setCustomLayerPositions, isEnabled, createDraw])

  return (
    <MapDrawContext.Provider
      value={{
        draw: drawRef.current,
        setSnapping,
        setOnCreate,
        setOnDelete,
        setOnUpdate,
        setOnRender,
        setFeaturePropertyAndRepaint,
        attachedToMap,
        isEnabled,
        setIsEnabled,
      }}
    >
      {children}
    </MapDrawContext.Provider>
  )
}
