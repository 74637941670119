import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table'
import { client, gql } from '../../graphql/client'
import {
  DataSourceData,
  MapSource,
  Parcel,
  Sample,
  SamplePlan,
  SamplePlanBlock,
  SamplePlanCreateDataSet,
  SamplingStatisticalMethod,
  SamplingStatisticalMethodDataSourceFile,
  SamplingType,
  Translation,
} from '../../graphql/types'
import { Fetcher, createTableFetcher } from '../../UI/Table/createTableFetcher'
import { TableFormatter } from '../../UI/Table/types'
import { groupArray } from '../../util/groupArray'
import { postJson } from '../../vvapi/apiResource/createApiResource'
import { NoteFormV2 } from '../../vvapi/models'
import { SampleFieldWithValue } from './Map/sampleFormReducer'
import { SampleQuality } from './SamplePlanDashboard/SamplePlanNew/types/SampleQuality'

const fetchSamplePlanResultsQuery = (
  returning: string,
  order_by: string
) => gql`
  query FETCH_SAMPLE_PLAN_RESULTS(
    $offset: Int!
    $limit: Int!
    $where: Sample_bool_exp
  ) {
    data: Sample(
      where: $where 
      order_by: ${order_by}
      offset: $offset
      limit: $limit
    ) ${returning}

    aggregate: Sample_aggregate(
      where: $where, 
    ) {
      info: aggregate { count }
    }
  }
`

const fetchSamplePlanQuery = () => gql`
  query FETCH_SAMPLE_PLAN_BY_ID($samplePlanId: uuid!, $tileTypes: [citext!]!) {
    data: SamplePlan_by_pk(id: $samplePlanId) {
      id
      name
      isPending
      createdAt
      templateName
      updatedAt
      NoteForm {
        organizationId
        name
        fields
        pinColor
      }
      SamplePlanCreateDataSet {
        id
        SamplingStatisticalMethod {
          id
          name
        }
        statisticalMethodFields
        statisticalMethodDataSources
      }
      Samples {
        id
        samplePlanBlockId
        completedAt
        completedBy
        CompletedByUser {
          firstName
          lastName
        }
        content
        geometry
        plantID
        rowID
        properties
        plantIndex
      }
      SamplePlanBlocks(order_by: { Parcel: { name: asc } }) {
        id
        Parcel {
          id
          name
          geometry
          Organization {
            name
          }
          OrganizationGroup: Group {
            name
          }
          DeliveryParcels {
            DeliveryParcelFiles(where: { filename: { _in: $tileTypes } }) {
              filename
              flightDate
              MapSource {
                id
                mapSourceDefId
                deliveryId
                parcelId
                meta
                flightDate

                type
                scheme
                attribution
                tileSize
                minzoom
                maxzoom
                bounds
                tiles

                statsData
                properties

                MapLayers {
                  id
                  deliveryId
                  mapSourceId
                  MapLayerDef {
                    id
                    name
                    MapSourceDef {
                      id
                      type
                    }
                  }
                  enabled
                }
              }
            }
          }
        }
      }
    }
  }
`

const fetchSamplePlanBlockQuery = () => gql`
  query FETCH_SAMPLE_PLAN_BLOCK_BY_ID(
    $samplePlanBlockId: uuid!
    $tileTypes: [citext!]!
  ) {
    data: SamplePlanBlock_by_pk(id: $samplePlanBlockId) {
      id
      Parcel {
        id
        name
        geometry
        Organization {
          name
        }
        OrganizationGroup: Group {
          name
        }
        DeliveryParcels {
          DeliveryParcelFiles(where: { filename: { _in: $tileTypes } }) {
            filename
            flightDate
            MapSource {
              id
              mapSourceDefId
              deliveryId
              parcelId
              meta
              flightDate

              type
              scheme
              attribution
              tileSize
              minzoom
              maxzoom
              bounds
              tiles

              statsData
              properties

              MapLayers {
                id
                deliveryId
                mapSourceId
                MapLayerDef {
                  id
                  name
                  MapSourceDef {
                    id
                    type
                  }
                }
                enabled
              }
            }
          }
        }
      }
      completedSamples: Samples_aggregate(
        where: { completedAt: { _is_null: false } }
      ) {
        aggregate {
          count
        }
      }
      totalSamples: Samples_aggregate {
        aggregate {
          count
        }
      }
      Samples {
        id
        completedAt
        completedBy
        CompletedByUser {
          firstName
          lastName
        }
        content
        geometry
        plantID
        rowID
        properties
        plantIndex
      }
    }
  }
`

interface GetSamplePlansParams {
  organizationId?: string
  columnFilters: MRT_ColumnFiltersState
  globalFilter: string
  sorting: MRT_SortingState
  pagination: MRT_PaginationState
}

interface GetSamplePlansResponse {
  SamplePlans: SamplePlan[]
  totalRowCount: {
    aggregate: {
      count: number
    }
  }
}

const getSamplePlansQuery = gql`
  query GET_SAMPLE_PLANS(
    $where: SamplePlan_bool_exp
    $order_by: [SamplePlan_order_by!]
    $offset: Int
    $limit: Int
  ) {
    SamplePlans: SamplePlan(
      where: $where
      order_by: $order_by
      offset: $offset
      limit: $limit
    ) {
      id
      name
      createdAt
      updatedAt
      templateName
      NoteForm {
        organizationId
        name
        pinColor
      }
      SamplePlanBlocks {
        Parcel {
          name
          OrganizationGroup: Group {
            name
          }
        }
      }
      isPending
      totalSamples: Samples_aggregate {
        aggregate {
          count
        }
      }
      completedSamples: Samples_aggregate(
        where: { completedAt: { _is_null: false } }
      ) {
        aggregate {
          count
        }
      }
    }
    totalRowCount: SamplePlan_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

const getSamplePlans = async ({
  organizationId,
  columnFilters,
  globalFilter,
  sorting,
  pagination,
}: GetSamplePlansParams) => {
  if (!organizationId) {
    return { SamplePlans: [], totalRowCount: 0 }
  }

  const where = {
    _and: [
      {
        organizationId: { _eq: organizationId },
        deletedAt: { _is_null: true },
        isPending: { _eq: false },
      },
    ] as any[],
  }

  const tableFilters = {
    _or: [] as any[],
  }

  const tableOrderBys = [] as any[]

  if (globalFilter) {
    tableFilters._or.push({
      _or: [
        { name: { _ilike: `%${globalFilter}%` } },
        { templateName: { _ilike: `%${globalFilter}%` } },
        {
          NoteForm: {
            name: {
              _ilike: `%${globalFilter}%`,
            },
          },
        },
        {
          SamplePlanBlocks: {
            Parcel: {
              name: {
                _ilike: `%${globalFilter}%`,
              },
            },
          },
        },
        {
          SamplePlanBlocks: {
            Parcel: {
              Group: {
                name: {
                  _ilike: `%${globalFilter}%`,
                },
              },
            },
          },
        },
      ],
    })
  }

  const localSort: ((samplePlans: SamplePlan[]) => SamplePlan[])[] = []

  if (sorting.length) {
    sorting.forEach((sort) => {
      switch (sort.id) {
        case 'name':
        case 'createdAt':
        case 'updatedAt':
          tableOrderBys.push({ [sort.id]: sort.desc ? 'desc' : 'asc' })
          break
        case 'samples':
          tableOrderBys.push({
            Samples_aggregate: { count: sort.desc ? 'desc' : 'asc' },
          })
          break
        case 'formType':
          tableOrderBys.push({
            NoteForm: {
              name: sort.desc ? 'desc' : 'asc',
            },
          })
          break
        case 'blockGroup':
          tableOrderBys.push({
            SamplePlanBlocks: {
              Parcel: {
                Group: {
                  name: sort.desc ? 'desc' : 'asc',
                },
              },
            },
          })
          break
        case 'blocks':
          localSort.push((samplePlans: SamplePlan[]) =>
            samplePlans.sort((a, b) => {
              const aBlockString =
                a?.SamplePlanBlocks?.map((block) => block.Parcel.name).join(
                  ' | '
                ) ?? ''
              const bBlockString =
                b?.SamplePlanBlocks?.map((block) => block.Parcel.name).join(
                  ' | '
                ) ?? ''

              return sort.desc
                ? bBlockString.localeCompare(aBlockString)
                : aBlockString.localeCompare(bBlockString)
            })
          )
          break
        case 'status':
          localSort.push((samplePlans: SamplePlan[]) =>
            samplePlans.sort((a, b) => {
              const aTotalSamples = a?.totalSamples?.aggregate?.count ?? 0
              const aCompletedSamples =
                a?.completedSamples?.aggregate?.count ?? 0
              const aSampleFraction = `${aCompletedSamples}/${aTotalSamples}`

              const bTotalSamples = b?.totalSamples?.aggregate?.count ?? 0
              const bCompletedSamples =
                b?.completedSamples?.aggregate?.count ?? 0
              const bSampleFraction = `${bCompletedSamples}/${bTotalSamples}`

              return sort.desc
                ? bSampleFraction.localeCompare(aSampleFraction)
                : aSampleFraction.localeCompare(bSampleFraction)
            })
          )
          break
        default:
          break
      }
    })
  }

  const localFilters: ((samplePlans: SamplePlan[]) => SamplePlan[])[] = []

  if (columnFilters.length) {
    columnFilters.forEach((filter) => {
      if (filter.value) {
        switch (filter.id) {
          case 'createdAt':
            const [createdMin, createMax] = filter.value as [string, string]
            if (createdMin && createMax) {
              tableFilters._or.push({
                [filter.id]: {
                  _lte: createMax,
                  _gte: createdMin,
                },
              })
            }
            if (createMax && !createdMin) {
              tableFilters._or.push({
                [filter.id]: {
                  _lte: createMax,
                },
              })
            }
            if (createdMin && !createMax) {
              tableFilters._or.push({
                [filter.id]: {
                  _gte: createdMin,
                },
              })
            }
            break
          case 'updatedAt':
            const [updatedMin, updatedMax] = filter.value as [string, string]
            if (updatedMin && updatedMax) {
              tableFilters._or.push({
                [filter.id]: {
                  _lte: updatedMax,
                  _gte: updatedMin,
                },
              })
            }
            if (updatedMax && !updatedMin) {
              tableFilters._or.push({
                [filter.id]: {
                  _lte: updatedMax,
                },
              })
            }
            if (updatedMin && !updatedMax) {
              tableFilters._or.push({
                [filter.id]: {
                  _gte: updatedMin,
                },
              })
            }
            break
          case 'blocks':
            const selectedParcelIds = filter.value as string[]
            tableFilters._or.push({
              SamplePlanBlocks: {
                Parcel: {
                  id: {
                    _in: selectedParcelIds,
                  },
                },
              },
            })
            break
          case 'blockGroup':
            const selectedGroupIds = filter.value as string[]
            tableFilters._or.push({
              SamplePlanBlocks: {
                Parcel: {
                  _and: [
                    {
                      Group: {
                        id: {
                          _in: selectedGroupIds,
                        },
                      },
                    },
                  ],
                },
              },
            })
            break
          case 'formType':
            const formTypes = filter.value as string[]
            tableFilters._or.push({
              NoteForm: {
                _and: [
                  {
                    id: {
                      _in: formTypes,
                    },
                  },
                ],
              },
            })
            break
          case 'status':
            const status = filter.value as string[]
            localFilters.push((samplePlans: SamplePlan[]) =>
              samplePlans.filter((samplePlan) => {
                const totalSamples =
                  samplePlan?.totalSamples?.aggregate?.count ?? 0
                const completedSamples =
                  samplePlan?.completedSamples?.aggregate?.count ?? 0
                return status.some((s) => {
                  switch (s) {
                    case 'complete':
                      return totalSamples === completedSamples
                    case 'in-progress':
                      return totalSamples !== completedSamples
                    default:
                      return false
                  }
                })
              })
            )
            break
          case 'samples':
            localFilters.push((samplePlans: SamplePlan[]) =>
              samplePlans.filter((samplePlan) => {
                const totalSamples =
                  samplePlan?.totalSamples?.aggregate?.count ?? 0
                const completedSamples =
                  samplePlan?.completedSamples?.aggregate?.count ?? 0
                const sampleFractionString = `${completedSamples}/${totalSamples}`
                return sampleFractionString.includes(filter.value as string)
              })
            )
            break
          default:
            break
        }
      }
    })
  }

  if (tableFilters._or.length) {
    where._and.push(tableFilters)
  }

  const offset = pagination.pageIndex * pagination.pageSize
  const limit = pagination.pageSize

  const { SamplePlans, totalRowCount } =
    await client.request<GetSamplePlansResponse>({
      query: getSamplePlansQuery,
      variables: {
        where: where,
        order_by: tableOrderBys,
        offset,
        limit,
      },
    })

  const filteredSamplePlans = localFilters.reduce(
    (acc, filter) => filter(acc),
    SamplePlans
  )

  const sortedSamplePlans = localSort.reduce(
    (acc, sort) => sort(acc),
    filteredSamplePlans
  )

  return {
    SamplePlans: sortedSamplePlans,
    totalRowCount: totalRowCount.aggregate.count,
  }
}

const fetchSamplePlanResults = (
  organizationId: number,
  samplePlanId: string,
  tableFormatters: TableFormatter<Sample>[]
) => {
  const list: Fetcher<Sample> = async (options) => {
    const where = {
      _and: {
        SamplePlan: { organizationId: { _eq: organizationId } },
        samplePlanId: { _eq: samplePlanId },
        ...(options?.where ?? {}),
      },
    }

    const order_by =
      options.order_by?.replace(/\s/g, '') ??
      '[{ completedAt: desc_nulls_last }]'

    const { data, aggregate } = await client.request<{
      data?: Sample[]
      aggregate?: { info: { count: number } }
    }>({
      query: fetchSamplePlanResultsQuery(options.returning ?? '', order_by),
      variables: {
        offset: options.offset,
        limit: options.limit,
        where: where,
      },
    })

    return {
      data,
      info: aggregate?.info,
    }
  }

  return createTableFetcher<Sample>(
    tableFormatters,
    `{
      id
      completedAt
      CompletedByUser {
        firstName
        lastName
      }
      SamplePlanBlock {
        Parcel {
          OrganizationGroup: Group {
            name
          }
          name
        }
      }
      content
      geometry
    }`,
    list
  )
}

const deleteSamplePlanMutation = gql`
  mutation DELETE_SAMPLE_PLAN($samplePlanId: uuid!, $now: timestamptz!) {
    update_SamplePlan_by_pk(
      pk_columns: { id: $samplePlanId }
      _set: { deletedAt: $now }
    ) {
      id
      deletedAt
    }
  }
`

const deleteSamplePlan = (samplePlanId: string) => {
  return client.request<{ id: string; deletedAt: string }>({
    query: deleteSamplePlanMutation,
    variables: { samplePlanId, now: new Date(Date.now()) },
  })
}

const markAsCompleteMutation = gql`
  mutation MARK_SAMPLES_AS_COMPLETE($samplePlanId: uuid!, $now: timestamptz!) {
    update_Sample(
      where: {
        _and: {
          samplePlanId: { _eq: $samplePlanId }
          completedAt: { _is_null: true } # only set completed at if empty
        }
      }
      _set: { completedAt: $now }
    ) {
      returning {
        id
      }
    }
  }
`

const markAsComplete = (samplePlanId: string) => {
  return client.request<{ id: string; completedAt: string }>({
    query: markAsCompleteMutation,
    variables: { samplePlanId, now: new Date(Date.now()) },
  })
}

const fetchSamplePlan = async (
  [userId, orgId, samplePlanId]: [
    userId: number,
    orgId: number,
    samplePlanId: string
  ],
  skip: () => void
) => {
  if (!userId || !orgId || !samplePlanId) {
    return skip()
  }

  return client.request<{ data: SamplePlan }>({
    query: fetchSamplePlanQuery(),
    variables: { samplePlanId, tileTypes: ['RGB.tif'] },
  })
}

const fetchSamplePlanBlock = async ([samplePlanBlockId]: [
  samplePlanBlockId: string
]) => {
  return client.request<{ data: SamplePlanBlock }>({
    query: fetchSamplePlanBlockQuery(),
    variables: { samplePlanBlockId, tileTypes: ['RGB.tif'] },
  })
}

const fetchOrganizationNoteFormTemplatesQuery = gql`
  query GET_ORG_NOTE_FORMS($orgId: Int!) {
    data: NoteForm(
      where: {
        _or: [
          { organizationId: { _eq: $orgId } }
          { organizationId: { _is_null: true } }
        ]
      }
    ) {
      name
      id
      organizationId
    }
  }
`

const fetchOrganizationNoteFormTemplates = async (
  [orgId]: [orgId: number],
  skip: () => void
) => {
  if (!orgId) {
    return skip()
  }

  return client.request<{ data: NoteFormV2[] }>({
    query: fetchOrganizationNoteFormTemplatesQuery,
    variables: { orgId },
  })
}

const fetchOrganizationParcelsQuery = gql`
  query FETCH_PARCELS_FOR_NEW_SAMPLE_PLAN(
    $where: Parcel_bool_exp
    $tileTypes: [citext!]!
  ) {
    data: Parcel(where: $where, order_by: { name: asc }) {
      id
      name
      geometry
      OrganizationGroup: Group {
        id
        name
      }
      DeliveryParcels {
        DeliveryParcelFiles(where: { filename: { _in: $tileTypes } }) {
          filename
          flightDate
          MapSource {
            id
            mapSourceDefId
            deliveryId
            parcelId
            meta
            flightDate

            type
            scheme
            attribution
            tileSize
            minzoom
            maxzoom
            bounds
            tiles

            MapLayers {
              id
              deliveryId
              mapSourceId
              MapLayerDef {
                id
                name
                MapSourceDef {
                  id
                  type
                }
              }
              enabled
            }
          }
        }
      }
    }
  }
`

const fetchOrganizationParcels = async (
  [orgId, search, flightDate]: [
    orgId: number,
    search?: string,
    flightDate?: string
  ],
  skip: () => void
) => {
  if (!orgId) {
    return skip()
  }

  let where: any = {
    _and: {
      organizationId: { _eq: orgId },
      deletedAt: { _is_null: true },
    },
  }

  if (search) {
    where = {
      ...where,
      _and: {
        ...where._and,
        _or: [
          { Group: { name: { _ilike: `%${search}%` } } },
          { name: { _ilike: `%${search}%` } },
        ],
      },
    }
  }

  if (flightDate) {
    where = {
      ...where,
      _and: {
        ...where._and,
        DeliveryParcels: {
          MapSources: {
            flightDate: { _eq: flightDate },
          },
        },
      },
    }
  }

  return client.request<{ data: Parcel[] }>({
    query: fetchOrganizationParcelsQuery,
    variables: {
      where: where,
      tileTypes: ['RGB.tif'],
    },
  })
}

const fetchSamplingTypesQuery = gql`
  query FETCH_SAMPLING_TYPES {
    data: SamplingType {
      id
      name
    }
  }
`

const fetchSamplingTypes = async () => {
  return client.request<{ data: SamplingType[] }>({
    query: fetchSamplingTypesQuery,
  })
}

const fetchStatisticalMethodsQuery = gql`
  query FETCH_SAMPLING_TYPES {
    data: SamplingStatisticalMethod(where: { enabled: { _eq: true } }) {
      id
      name
      info
      SamplingStatisticalMethodFields(order_by: { order: asc }) {
        id
        name
        type
        required
        description
        defaultValue
        valueType
      }
      SamplingStatisticalMethodDataSources {
        id
        name
        SamplingStatisticalMethodDataSourceFiles {
          dataSourceId
          fileType
          priority
          sourceType
          required
        }
        required
        description
      }
    }
  }
`

const fetchStatisticalMethods = async () => {
  return client.request<{ data: SamplingStatisticalMethod[] }>({
    query: fetchStatisticalMethodsQuery,
  })
}

const fetchDataSourceListQuery = gql`
  query FETCH_DATASOURCE_MAP_SOURCES($orgId: Int!) {
    data: MapSource(
      where: {
        _and: {
          organizationId: { _eq: $orgId }
          MapLayers: { enabled: { _eq: true } }
        }
      }
    ) {
      parcelId
      flightDate
    }
  }
`

const fetchDataSourceFlightDateList = async (
  [orgId]: [orgId: number],
  skip: () => void
): Promise<string[] | void> => {
  if (!orgId) {
    return skip()
  }

  const sources = await client.request<{
    data: MapSource[]
  }>({
    query: fetchDataSourceListQuery,
    variables: {
      orgId,
    },
  })

  const flightDates = Object.keys(groupArray(sources.data, (d) => d.flightDate))

  return flightDates
}

const fetchDataSourceOptionsQuery = gql`
  query FETCH_DATASOURCE_MAP_SOURCES(
    $orgId: Int!
    $parcelIds: [Int!]
    $sourceFileTypes: [citext!]!
  ) {
    data: MapSource(
      where: {
        _and: {
          organizationId: { _eq: $orgId }
          parcelId: { _in: $parcelIds }
          filename: { _in: $sourceFileTypes }
          MapLayers: { enabled: { _eq: true } }
        }
      }
    ) {
      id
      parcelId
      filename
      flightDate
      url
      type
      scheme
      attribution
      tileSize
      minzoom
      maxzoom
      bounds
      tiles
    }
  }
`

const fetchDataSourceOptions = async (
  [orgId, parcelIds, sourceFileTypes, required]: [
    orgId: number,
    parcelIds: number[],
    sourceFileTypes: SamplingStatisticalMethodDataSourceFile[],
    required: boolean
  ],
  skip: () => void
): Promise<{
  data: DataSourceData[]
  dataSources: Record<string, MapSource[]>
  inconsistentBlockData: boolean
} | void> => {
  if (!orgId || !parcelIds || !sourceFileTypes) {
    return skip()
  }

  const deliveryParcelFiles = await client.request<{
    data: MapSource[]
  }>({
    query: fetchDataSourceOptionsQuery,
    variables: {
      orgId,
      parcelIds,
      sourceFileTypes: sourceFileTypes.map((sft) => sft.fileType),
    },
  })

  // create a mapping for the current methods expected source file types.
  // This will be used to filter the data sources.
  const sourceFileTypeMap = sourceFileTypes.reduce((acc, sft) => {
    if (!acc[sft.fileType]) {
      acc[sft.fileType] = {
        sourceType: sft.sourceType,
        priority: sft.priority,
        required: sft.required,
      }
    }
    return acc
  }, {} as Record<string, { sourceType: string; priority: number; required: boolean }>)

  const plantLevelSourceTypes = sourceFileTypes.filter(
    (sft) => sourceFileTypeMap[sft.fileType].sourceType === 'plant'
  )

  const plantLevelRequired =
    plantLevelSourceTypes.length !== 0 &&
    plantLevelSourceTypes.every((sft) => {
      return sourceFileTypeMap[sft.fileType].required
    })

  const zoneLevelSourceTypes = sourceFileTypes.filter(
    (sft) => sourceFileTypeMap[sft.fileType].sourceType === 'zone'
  )
  const zoneLevelRequired =
    zoneLevelSourceTypes.length !== 0 &&
    zoneLevelSourceTypes.every((sft) => {
      return sourceFileTypeMap[sft.fileType].required
    })

  // get all the data sources for the plants.
  const plantLevelDataSources = deliveryParcelFiles.data
    .filter((dpf) => sourceFileTypeMap[dpf.filename].sourceType === 'plant')
    .sort((a, b) => {
      return sourceFileTypeMap[a.filename].priority >
        sourceFileTypeMap[b.filename].priority
        ? 1
        : -1
    })

  // get all the data sources for the zones.
  const zoneLevelDataSources = deliveryParcelFiles.data
    .filter((dpf) => sourceFileTypeMap[dpf.filename].sourceType === 'zone')
    .sort((a, b) => {
      return sourceFileTypeMap[a.filename].priority >
        sourceFileTypeMap[b.filename].priority
        ? 1
        : -1
    })

  // group the plant level data sources by flight date.
  const plantLevelDataSourcesByFlightDate = groupArray(
    plantLevelDataSources,
    (plds) => `${plds.flightDate}`
  )

  // group the zone level data source by flight date.
  const zoneLevelDataSourcesByFlightDate = groupArray(
    zoneLevelDataSources,
    (zlds) => `${zlds.flightDate}`
  )

  // find a flight with a data source for each parcel if possible.
  const availablePlantDataSources = Object.entries(
    plantLevelDataSourcesByFlightDate
  ).filter(([_, dataSources]) => {
    return parcelIds.every((parcelId) =>
      dataSources.some((ds) => Number(ds.parcelId) === parcelId)
    )
  })

  const availableZoneDataSource = Object.entries(
    zoneLevelDataSourcesByFlightDate
  ).filter(([_, dataSources]) => {
    return parcelIds.every((parcelId) =>
      dataSources.some((ds) => Number(ds.parcelId) === parcelId)
    )
  })

  const filteredFlightDatesExist =
    availablePlantDataSources.length !==
      Object.keys(zoneLevelDataSourcesByFlightDate).length &&
    availableZoneDataSource.length !==
      Object.keys(zoneLevelDataSourcesByFlightDate).length

  // return empty data if there are no datasource and they are required.
  if (availablePlantDataSources.length === 0 && plantLevelRequired) {
    return {
      data: [],
      dataSources: {},
      inconsistentBlockData: filteredFlightDatesExist,
    }
  }

  if (availableZoneDataSource.length === 0 && zoneLevelRequired) {
    return {
      data: [],
      dataSources: {},
      inconsistentBlockData: filteredFlightDatesExist,
    }
  }

  // return empty data if there is are no matching dates between the zones and plants
  const allAvailableDataSource = availablePlantDataSources
    .concat(availableZoneDataSource)
    .reduce((acc, [flightDate, mapSources]) => {
      acc[flightDate] = [...(acc?.[flightDate] ?? []), ...mapSources]
      return acc
    }, {} as Record<string, MapSource[]>)

  return {
    data: Object.entries(allAvailableDataSource).map((g) => ({
      flightDate: g?.[1][0].flightDate,
      deliveryParcelUrls: g?.[1].map((dpf) => ({
        dataSourceFileId:
          sourceFileTypes.find((sft) => sft.fileType === dpf.filename)?.id ??
          '',
        parcelId: dpf.parcelId,
        url: dpf.url,
        sourceType:
          sourceFileTypes.find((sft) => sft.fileType === dpf.filename)
            ?.sourceType ?? '',
      })),
      required,
    })),
    dataSources: allAvailableDataSource,
    inconsistentBlockData: filteredFlightDatesExist,
  }
}

const getSampleQualityByBlock = async (
  [newSamplingPlan, orgId, userId, previousSampleCountsAndQuality]: [
    newSamplePlan: SamplePlanCreateDataSet,
    orgId: string,
    userId: string,
    previousSampleCountsAndQuality: Record<
      string,
      [number, SampleQuality | null]
    >
  ],
  skip: () => void
) => {
  if (
    !newSamplingPlan ||
    !orgId ||
    !userId ||
    !newSamplingPlan?.blockSamplesData ||
    newSamplingPlan?.blockSamplesData?.length === 0
  ) {
    return skip()
  }

  if (
    !newSamplingPlan?.blockSamplesData ||
    newSamplingPlan?.blockSamplesData?.length === 0
  ) {
    return skip()
  }

  const { sampleQualityByBlock } = await postJson(
    `/api/v3/sampling-plan/sample-quality`,
    {
      body: {
        ...newSamplingPlan,
        createByUserId: userId,
        organizationId: orgId,
      },
    }
  )

  const previousSampleQualityByBlock = Object.fromEntries(
    Object.entries(previousSampleCountsAndQuality).map(
      ([parcelId, [_, quality]]) => {
        return [parcelId, quality]
      }
    )
  )

  // overwrite previous sample quality by block with new sample quality by block
  const updatedSampleQualityByBlock = {
    ...previousSampleQualityByBlock,
    ...sampleQualityByBlock,
  }

  return {
    data: updatedSampleQualityByBlock as Record<string, SampleQuality | null>,
  }
}

const generateSamples = async (
  [newSamplingPlan, orgId, userId, completedSamplePlan]: [
    newSamplePlan: SamplePlanCreateDataSet,
    orgId: string,
    userId: string,
    completedSamplePlan: SamplePlan
  ],
  skip: () => void
) => {
  if (!newSamplingPlan || !orgId || !userId) {
    return skip()
  }

  // if a completed sample plan already exists, return.
  if (!!completedSamplePlan) {
    return skip()
  }

  const {
    samplePlanName,
    noteFormTemplateId,
    projectInstructions,
    statisticalMethodTypeId,
    statisticalMethodFields,
    statisticalMethodDataSources,
    blockSamplesData,
  } = newSamplingPlan

  if (!samplePlanName || !noteFormTemplateId || !statisticalMethodTypeId) {
    return skip()
  }

  const { samplePlan } = await postJson(
    `/api/v3/sampling-plan/generate-samples`,
    {
      body: {
        samplePlanName,
        noteFormTemplateId,
        templateName: newSamplingPlan?.NoteForm?.name,
        projectInstructions,
        statisticalMethodTypeId,
        statisticalMethodFields,
        statisticalMethodDataSources,
        createByUserId: userId,
        organizationId: orgId,
        blockSamplesData,
      },
    }
  )
  return { data: samplePlan as SamplePlan }
}

const deleteStaleSamplePlanQuery = gql`
  mutation DELETE_STALE_SAMPLE_PLAN($samplePlanId: uuid!, $projectId: uuid!) {
    samplePlanData: delete_SamplePlan_by_pk(id: $samplePlanId) {
      samplePlanId: id
    }
    projectData: delete_Project_by_pk(id: $projectId) {
      projectId: id
    }
  }
`

const deleteStaleSamplePlan = ([samplePlanId, projectId]: [
  samplePlanId: string,
  projectId: string
]) => {
  // if a completed sample plan already exists, return.
  if (!samplePlanId) {
    return
  }
  return client.request<{
    samplePlanData: { samplePlanId: string }
    projectData: { projectId: string }
  }>({
    query: deleteStaleSamplePlanQuery,
    variables: { samplePlanId, projectId },
  })
}

const commitNewSamplingPlanQuery = gql`
  mutation COMMIT_NEW_SAMPLING_PLAN($samplePlanId: uuid!) {
    data: update_SamplePlan_by_pk(
      pk_columns: { id: $samplePlanId }
      _set: { isPending: false }
    ) {
      samplePlanId: id
    }
  }
`

const commitNewSamplingPlan = ([samplePlanId]: [samplePlanId: string]) => {
  if (!samplePlanId) {
    return
  }
  return client.request<{ data: { samplePlanId: string } }>({
    query: commitNewSamplingPlanQuery,
    variables: { samplePlanId },
  })
}

const fetchSampleQuery = gql`
  query FETCH_SAMPLE($sampleId: uuid!) {
    data: Sample_by_pk(id: $sampleId) {
      id
      completedAt
      completedBy
      CompletedByUser {
        firstName
        lastName
      }
      content
      geometry
      plantID
      rowID
      properties
      SamplePlan {
        NoteForm {
          id
          organizationId
        }
      }
    }
  }
`

const fetchSample = async (
  [sampleId]: [sampleId: string],
  skip: () => void
) => {
  if (!sampleId) {
    return skip()
  }

  return client.request<{
    data: Sample
  }>({
    query: fetchSampleQuery,
    variables: { sampleId },
  })
}

const completeSampleMutation = gql`
  mutation COMPLETE_SAMPLE(
    $sampleId: uuid!
    $content: jsonb!
    $completedAt: timestamptz!
  ) {
    data: update_Sample_by_pk(
      pk_columns: { id: $sampleId }
      _set: { content: $content, completedAt: $completedAt }
    ) {
      id
      samplePlanBlockId
      completedAt
      completedBy
      CompletedByUser {
        firstName
        lastName
      }
      content
      geometry
      plantID
      rowID
      properties
      plantIndex
    }
  }
`

const completeSample = async ([sampleId, content]: [
  sampleId?: string,
  content?: Record<string, SampleFieldWithValue>
]) => {
  if (!sampleId || !content) {
    return
  }
  return client.request<{ data: Sample }>({
    query: completeSampleMutation,
    variables: { sampleId, content, completedAt: new Date(Date.now()) },
  })
}

const fetchSamplePlanBlocksQuery = () => gql`
  query FETCH_SAMPLE_PLAN_BLOCKS_BY_IDS(
    $samplePlanBlockIds: [uuid!]!
    $tileTypes: [citext!]!
  ) {
    data: SamplePlanBlock(where: { id: { _in: $samplePlanBlockIds } }) {
      id
      Parcel {
        id
        name
        geometry
        Organization {
          name
        }
        OrganizationGroup: Group {
          name
        }
        DeliveryParcels {
          DeliveryParcelFiles(where: { filename: { _in: $tileTypes } }) {
            filename
            flightDate
            MapSource {
              id
              mapSourceDefId
              deliveryId
              parcelId
              meta
              flightDate

              type
              scheme
              attribution
              tileSize
              minzoom
              maxzoom
              bounds
              tiles

              statsData
              properties

              MapLayers {
                id
                deliveryId
                mapSourceId
                MapLayerDef {
                  id
                  name
                  MapSourceDef {
                    id
                    type
                  }
                }
                enabled
              }
            }
          }
        }
      }
      Samples {
        id
        completedAt
        completedBy
        CompletedByUser {
          firstName
          lastName
        }
        content
        geometry
        plantID
        rowID
        properties
        plantIndex
      }
    }
  }
`

const fetchSamplePlanBlocks = async ([samplePlanBlockIds]: [
  samplePlanBlockId: string[]
]) => {
  if (!samplePlanBlockIds) {
    return
  }
  return client.request<{ data: SamplePlanBlock[] }>({
    query: fetchSamplePlanBlocksQuery(),
    variables: { samplePlanBlockIds, tileTypes: ['RGB.tif'] },
  })
}

const fetchSamplePlanForDuplicationQuery = gql`
  query FETCH_SAMPLE_PLAN_FOR_DUPLICATION($samplePlanId: uuid!) {
    samplePlan: SamplePlan_by_pk(id: $samplePlanId) {
      id
      name
      formId
      templateName
      organizationId
      samplePlanCreateDataSetId
      projectId
      duplicateOfId
      SamplePlanBlocks {
        id
        parcelId
        generatedSampleFeatures
        samplingStatisticalMethodId
      }
      Samples {
        samplePlanBlockId
        geometry
        content
        plantID
        rowID
        plantIndex
        properties
      }
    }
  }
`

const duplicateSamplePlanMutation = gql`
  mutation DUPLICATE_SAMPLE_PLAN($samplePlan: SamplePlan_insert_input!) {
    data: insert_SamplePlan_one(object: $samplePlan) {
      id
    }
  }
`

const duplicateSamplePlanBlocksAndSamplesMutation = gql`
  mutation DUPLICATE_SAMPLE_PLAN_SAMPLES(
    $samplePlanBlocks: [SamplePlanBlock_insert_input!]!
  ) {
    data: insert_SamplePlanBlock(objects: $samplePlanBlocks) {
      returning {
        id
      }
    }
  }
`

const duplicateSamplePlan = async (
  samplePlanId: string,
  newSamplePlanName: string
) => {
  if (!samplePlanId) {
    throw Error('Sample plan ID is required.')
  }

  const { samplePlan } = await client.request<{ samplePlan: SamplePlan }>({
    query: fetchSamplePlanForDuplicationQuery,
    variables: { samplePlanId },
  })

  if (!samplePlan) {
    throw Error('Sample plan not found.')
  }

  const {
    id,
    formId,
    templateName,
    organizationId,
    samplePlanCreateDataSetId,
    projectId,
    SamplePlanBlocks,
    Samples,
  } = samplePlan

  if (!SamplePlanBlocks || SamplePlanBlocks?.length === 0) {
    throw Error('Sample plan blocks are missing.')
  }

  if (!Samples || Samples?.length === 0) {
    throw Error('Samples are missing.')
  }

  const samplesByBlockId = Samples.reduce((acc, sample) => {
    if (!acc[sample.samplePlanBlockId]) {
      acc[sample.samplePlanBlockId] = []
    }
    acc[sample.samplePlanBlockId].push(sample)
    return acc
  }, {} as Record<string, Sample[]>)

  const { data } = await client.request<{ data: SamplePlan }>({
    query: duplicateSamplePlanMutation,
    variables: {
      samplePlan: {
        name: newSamplePlanName,
        formId,
        templateName,
        organizationId,
        samplePlanCreateDataSetId,
        projectId,
        duplicateOfId: id,
        isPending: false,
      },
    },
  })

  const samplePlanBlocks = SamplePlanBlocks.map((block) => {
    const {
      id,
      parcelId,
      generatedSampleFeatures,
      samplingStatisticalMethodId,
    } = block
    return {
      samplePlanId: data.id,
      parcelId,
      generatedSampleFeatures,
      samplingStatisticalMethodId,
      Samples: {
        data: samplesByBlockId[id].map((sample) => {
          const { geometry, content, plantID, rowID, plantIndex, properties } =
            sample
          return {
            samplePlanId: data.id,
            geometry,
            content: Object.entries(content).reduce(
              (acc, [key, contentItem]) => {
                // remove value from content object
                const { value, ...other } = contentItem
                acc[key] = { ...other }
                return acc
              },
              {} as Record<string, any>
            ),
            plantID,
            rowID,
            plantIndex,
            properties,
          }
        }),
      },
    }
  })

  try {
    await client.request<{ data: { id: string } }>({
      query: duplicateSamplePlanBlocksAndSamplesMutation,
      variables: {
        samplePlanBlocks: samplePlanBlocks,
      },
    })
  } catch (e) {
    await deleteSamplePlan(data.id)
    throw e
  }

  return data
}

export interface DownloadPlanCsvData {
  parcelId: string
  organizationName: string
  organizationGroupName: string
  sampleNoteTemplateName: string
  block: string
  methodNameTranslation: Translation
  statisticalMethodFields: Record<string, any>
  fieldTranslations: Record<string, Translation>
  statisticalMethodDataSources: Record<string, any>
  rowCounts: Record<number, number>
}

const fetchSamplePlanDownloadCSVDataQuery = gql`
  query FETCH_SAMPLE_PLAN_DOWNLOAD_CSV_DATA($samplePlanId: uuid!) {
    data: View_DownloadSampleCSVData(
      where: { samplePlanId: { _eq: $samplePlanId } }
    ) {
      parcelId
      organizationName
      organizationGroupName
      sampleNoteTemplateName
      block
      methodNameTranslation
      statisticalMethodFields
      fieldTranslations
      statisticalMethodDataSources
      rowCounts
    }
  }
`

const fetchSamplePlanDownloadCSVData = async (samplePlanId: string) => {
  return client.request<{ data: DownloadPlanCsvData[] }>({
    query: fetchSamplePlanDownloadCSVDataQuery,
    variables: { samplePlanId },
  })
}

interface GetFilterOptionsParams {
  organizationId?: string
}

interface GetFilterResponse {
  parcelOptions: {
    value: string
    label: Translation
  }[]
  groupOptions: {
    value: string
    label: string
  }[]
  formOptions: {
    value: string
    label: string
  }[]
}

const getFilterOptionsQuery = gql`
  query GET_SAMPLE_PLAN_FILTER_OPTIONS($organizationId: Int!) {
    parcelOptions: Parcel(
      where: {
        _and: {
          organizationId: { _eq: $organizationId }
          deletedAt: { _is_null: true }
        }
      }
    ) {
      value: id
      label: name
    }
    groupOptions: OrganizationGroup(
      where: {
        _and: {
          organizationId: { _eq: $organizationId }
          deletedAt: { _is_null: true }
        }
      }
    ) {
      value: id
      label: name
    }
    formOptions: NoteForm(
      where: {
        _and: {
          _or: [
            { organizationId: { _eq: $organizationId } }
            { organizationId: { _is_null: true } }
          ]
          id: { _neq: "00000000-0000-0000-0000-000000000004" }
        }
      }
    ) {
      value: id
      label: name
    }
  }
`

const getFilterOptions = async ({ organizationId }: GetFilterOptionsParams) => {
  if (!organizationId) {
    return { parcelOptions: [], groupOptions: [], formOptions: [] }
  }
  return client.request<GetFilterResponse>({
    query: getFilterOptionsQuery,
    variables: {
      organizationId,
    },
  })
}

export {
  commitNewSamplingPlan,
  completeSample,
  deleteSamplePlan,
  deleteStaleSamplePlan,
  duplicateSamplePlan,
  fetchDataSourceFlightDateList,
  fetchDataSourceOptions,
  fetchOrganizationNoteFormTemplates,
  fetchOrganizationParcels,
  fetchSample,
  fetchSamplePlan,
  fetchSamplePlanBlock,
  fetchSamplePlanBlocks,
  fetchSamplePlanDownloadCSVData,
  fetchSamplePlanResults,
  fetchSamplingTypes,
  fetchStatisticalMethods,
  generateSamples,
  getFilterOptions,
  getSamplePlans,
  getSampleQualityByBlock,
  markAsComplete,
}
