import { feature } from '@turf/helpers'
import { ExtraLayersStyle } from '../../../data/types'
import { FIELD_BOUNDS_UNSELECTED_LAYER_ID } from '../../../postgis/selectors/FieldsLayerSelector'
import { RootStore } from '../../../redux/types'
import { pureVineZonesLayerID } from '../constants'
import { RateMapContextState } from '../editor/RateMapContext'
import { createDeepEqualitySelector } from './createDeepEqualitySelector'

const getRateMapCustomZones = (state: RateMapContextState) =>
  state.rateMap?.RateMapCustomZones?.filter((zone) => !zone.deletedAt)

const EMPTY_STYLE: ExtraLayersStyle = {
  sources: {},
  layers: [],
}

const getSelectedCustomZoneId = (state: RateMapContextState) => {
  return state.selectedCustomZoneId
}
const getSelectedZoneIndex = (state: RateMapContextState) => {
  return state.selectedZoneIndex
}
const getShowFieldBounds = (_: RateMapContextState, state: RootStore) =>
  state.preferences.showFieldBoundaries

export const selectRateMapCustomZoneLayers = createDeepEqualitySelector(
  getRateMapCustomZones,
  getSelectedCustomZoneId,
  getSelectedZoneIndex,
  getShowFieldBounds,
  (
    customZones,
    selectedCustomZoneId,
    selectedZoneIndex,
    showFieldBoundaries
  ) => {
    const beforeLayer = showFieldBoundaries
      ? FIELD_BOUNDS_UNSELECTED_LAYER_ID
      : undefined

    if (!customZones || customZones.length === 0) {
      return EMPTY_STYLE
    }

    if (selectedCustomZoneId !== undefined) {
      return {
        sources: {
          'rate-map-custom-zones': {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: (customZones ?? []).map((zone) =>
                feature(zone.geometry, { color: zone.color, id: zone.id })
              ),
            },
          },
        },
        layers: [
          {
            afterLayerGroup: pureVineZonesLayerID,
            beforeLayer,
            id: 'rate-map-custom-zones',
            type: 'fill',
            source: 'rate-map-custom-zones',
            paint: {
              'fill-color': ['get', 'color'],
            },
            filter: ['==', ['get', 'id'], selectedCustomZoneId],
          },
        ],
      } as ExtraLayersStyle
    }

    if (selectedZoneIndex !== undefined) {
      return EMPTY_STYLE
    }

    return {
      sources: {
        'rate-map-custom-zones': {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: (customZones ?? []).map((zone) =>
              feature(zone.geometry, { color: zone.color })
            ),
          },
        },
      },
      layers: [
        {
          afterLayerGroup: pureVineZonesLayerID,
          beforeLayer,
          id: 'rate-map-custom-zones',
          type: 'fill',
          source: 'rate-map-custom-zones',
          paint: {
            'fill-color': ['get', 'color'],
          },
        },
      ],
    } as ExtraLayersStyle
  }
)
